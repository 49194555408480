import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "welcome-to-linkfive",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#welcome-to-linkfive",
        "aria-label": "welcome to linkfive permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Welcome to LinkFive!`}</h1>
    <p>{`We enable everyone to be successful with their app subscription businesses. We take care of the hard parts so that you don't have to. A solution to your problem is only one click away. No coding is required to build, measure, learn and optimize your subscriptions - just like you do with other parts of your app - so that you can focus on what you do best: Build great applications.`}</p>
    <h2 {...{
      "id": "subscription-management",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#subscription-management",
        "aria-label": "subscription management permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Subscription Management`}</h2>
    <p>{`We divide this into two areas, the management of the store subscription and the subscription playout.`}</p>
    <h3 {...{
      "id": "manage-your-store-subscriptions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#manage-your-store-subscriptions",
        "aria-label": "manage your store subscriptions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Manage your Store Subscriptions`}</h3>
    <p>{`With LinkFive it is possible to create and manage your subscriptions directly on our workspace and use them in your iOS and Android app. Subscriptions are automatically synced with the Google Play Store and Apple App Store.`}</p>
    <blockquote>
      <p parentName="blockquote">{`It is no longer necessary to switch to the respective platforms to create or edit your subscriptions.`}</p>
    </blockquote>
    <p>{`Read how to `}<a parentName="p" {...{
        "href": "/docs/dashboard/manage-subscriptions"
      }}>{`Manage your Subscriptions`}</a></p>
    <h3 {...{
      "id": "subscription-playout",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#subscription-playout",
        "aria-label": "subscription playout permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Subscription Playout`}</h3>
    <p>{`In order to distribute the subscriptions to the right users, we package the subscriptions in playout packages. The playout packages can be delivered to the right user group with filters. One filter is e.g. the target country, so that certain subscriptions can only be offered in certain countries.`}</p>
    <p>{`Read how to `}<a parentName="p" {...{
        "href": "/docs/dashboard/subscription-playout"
      }}>{`Playout your Subscriptions`}</a></p>
    <blockquote>
      <p parentName="blockquote">{`Next: `}<a parentName="p" {...{
          "href": "/docs/getting-started"
        }}>{`Getting started`}</a></p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      